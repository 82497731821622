import React, { useState } from 'react';
import Banner from '../../components/Banner';
import Calendar from '../../components/Calendar';
import LightBackground from '../../components/LightBackground';
import GalerieSelector from '../../components/GalerieSelector';
import Text from '../../components/Text';
import PictoBar from '../../components/PictoBar';
import { useIsMobileView } from '../../hooks/useIsMobileView';

/* Images */
import fordNuggetBanner from '../../images/night.jpeg';
import fordNuggetImage1 from '../../images/1.jpg';
import fordNuggetImage3 from '../../images/3.jpg';
import fordNuggetImage4 from '../../images/4.jpg';
import fordNuggetImage5 from '../../images/5.jpg';
import fordNuggetImage6 from '../../images/6.jpg';
import fordNuggetImage7 from '../../images/7.jpg';
import fordNuggetImage8 from '../../images/8.jpg';
import fordNuggetImage9 from '../../images/9.jpg';
import fordNuggetImage10 from '../../images/10.jpg';

/* Icons */
import { faBed, faUtensils, faCar, faSuitcase, faRoad, faMapMarkedAlt, faIdCard, faScrewdriverWrench, faShower, faGasPump, faToilet, faWater, faSmog, faWind, faGears, faCoins } from '@fortawesome/free-solid-svg-icons';

function FordNugget() {
    const isMobileView = useIsMobileView();
    const [nbDays, setNbDays] = useState(0);
    const price = 99;

    const handleDateChange = (dates) => {
        const [start, end] = dates;

        if (start && end) {
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            setNbDays(diffDays);
        } else {
            setNbDays(0);
        }
    };

    const handleConfirm = (dates) => {
        const popup = document.createElement('div');
        popup.className = `fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-lg z-50`;
        popup.innerHTML = `
            <div class="rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-lg p-6 max-w-[700px] w-full mx-4 text-white">
                <h2 class="text-2xl font-bold mb-4 text-center">Confirmation</h2>
                <p class="mb-4">Vous avez sélectionné les dates suivantes :</p>
                <p class="mb-4 text-center text-blue-500">${dates[0].toLocaleDateString()} - ${dates[1].toLocaleDateString()}.</p>
                <p class="mb-4">Le prix de la réservation sera de :</p>
                <p class="mb-4 text-center text-blue-500">${nbDays * price} € HT et ${nbDays * price * 1.2} € TTC.
                    <br /><span class="text-white">Une caution de 500€ sera demandée.</span>
                </p>
                <p class="mb-4">Veuillez entrer vos informations pour confirmer.</p>
                <div class="flex mb-4">
                    <input type="text" id="firstName" class="w-1/2 p-2 border border-gray-300 rounded-l bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Prénom" />
                    <input type="text" id="lastName" class="w-1/2 p-2 border border-gray-300 rounded-r bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Nom" />
                </div>
                <input type="email" id="email" class="w-full p-2 border border-gray-300 rounded mb-4 bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Votre email" />
                <input type="tel" id="phone" class="w-full p-2 border border-gray-300 rounded bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Votre numéro de téléphone" />
                <div class="flex items-center mb-4">
                    <input type="checkbox" id="consent" class="mr-2 p-2 rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg" />
                    <label for="consent" class="p-2">J'accepte d'être contacté et que mes données soient gardées chez C' Loc.</label>
                </div>
                <div id="errorMessages" class="text-red-500 mb-4"></div>
                <div class="flex justify-end">
                    <button id="cancelButton" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2">Annuler</button>
                    <button id="confirmButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Confirmer</button>
                </div>
                <div id="loadingAnimation" class="hidden mt-4">
                    <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
                    <p class="text-center mt-2">Chargement en cours...</p>
                </div>
            </div>
        `;

        document.body.appendChild(popup);

        document.getElementById('cancelButton').addEventListener('click', () => {
            document.body.removeChild(popup);
        });

        document.getElementById('confirmButton').addEventListener('click', () => {
            const firstName = document.getElementById('firstName').value;
            const lastName = document.getElementById('lastName').value;
            const email = document.getElementById('email').value;
            const phone = document.getElementById('phone').value;
            const consent = document.getElementById('consent').checked;
            const priceTTC = nbDays * price * 1.2;

            const firstNameRegex = /^[a-zA-Z]{2,30}$/;
            const lastNameRegex = /^[a-zA-Z]{2,30}$/;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const phoneRegex = /^[0-9]{10}$/;

            if (!firstName || !lastName || !email || !phone || !consent) {
                document.getElementById('errorMessages').innerText = 'Veuillez remplir tous les champs et accepter les conditions.';
                return;
            }

            if (!firstNameRegex.test(firstName)) {
                document.getElementById('errorMessages').innerText = 'Le prénom doit contenir entre 2 et 30 caractères.';
                return;
            }

            if (!lastNameRegex.test(lastName)) {
                document.getElementById('errorMessages').innerText = 'Le nom doit contenir entre 2 et 30 caractères.';
                return;
            }

            if (!emailRegex.test(email)) {
                document.getElementById('errorMessages').innerText = 'L\'email n\'est pas valide.';
                return;
            }

            if (!phoneRegex.test(phone)) {
                document.getElementById('errorMessages').innerText = 'Le numéro de téléphone n\'est pas valide.';
                return;
            }

            const reservationDates = `${dates[0].toLocaleDateString()} - ${dates[1].toLocaleDateString()}`;

            const csrftoken = getCookie('csrftoken');

            fetch('https://api.c-location.fr/forms/mail/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken,
                },
                credentials: 'include',
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    reservation_dates: reservationDates,
                    phone: phone,
                    price: priceTTC,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const successPopup = document.createElement('div');
                    successPopup.className = `fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-lg z-50`;
                    successPopup.innerHTML = `
                        <div class="rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-lg p-6 max-w-[700px] w-full mx-4 text-white">
                            <h2 class="text-2xl font-bold mb-4">Succès</h2>
                            <p class="mb-4">Utilisateur créé avec succès!</p>
                            <div class="flex justify-end">
                                <button id="closeSuccessPopup" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Fermer</button>
                            </div>
                        </div>
                    `;
                    document.body.appendChild(successPopup);

                    document.getElementById('closeSuccessPopup').addEventListener('click', () => {
                        document.body.removeChild(successPopup);
                    });

                    document.body.removeChild(popup);
                } else {
                    document.getElementById('errorMessages').innerText = data.error || 'Une erreur est survenue. Veuillez réessayer.';
                }
            })
            .catch(error => {
                if (error.errors && error.errors.email) {
                    document.getElementById('errorMessages').innerText = error.errors.email;
                } else {
                    document.getElementById('errorMessages').innerText = 'Une erreur est survenue. Veuillez réessayer.';
                }
                console.error('Erreur lors de la requête de création de l\'utilisateur:', error);
            });
        });
    };

    return (
        <div>
            <LightBackground />

            <div className="my-10">
                <Banner
                    title="Ford Nugget"
                    description="Découvrez le Ford Nugget, votre compagnon idéal pour des aventures inoubliables en van aménagé."
                    image={fordNuggetBanner}
                />
            </div>

            <div className="my-10">
                <div className={`max-w-[1920px] mx-auto ${isMobileView ? 'p-3' : 'm-0 px-10'}`}>
                    <div className={`rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg ${isMobileView ? 'p-3' : 'm-0 p-10'}`}>
                        <div className={`w-full ${isMobileView ? 'p-0' : 'p-10'}`}>
                            <div className="max-w-[1920px] mx-auto text-white">
                                <div className={`selector ${isMobileView ? 'm-3 p-5' : 'm-10 p-10 my-0'} rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg flex-row flex justify-center items-center`} tabIndex="0">
                                    <h2 className="text-2xl font-bold text-center text-white mx-auto">Réservation</h2>
                                </div>
                            </div>
                        </div>
                        <div className={`flex justify-between items-center w-full ${isMobileView ? 'p-0' : 'p-10'}`}>
                            <GalerieSelector images={[fordNuggetImage1, fordNuggetImage3, fordNuggetImage4, fordNuggetImage5, fordNuggetImage6, fordNuggetImage7, fordNuggetImage8, fordNuggetImage9, fordNuggetImage10]} />
                        </div>
                        <div className={`w-full ${isMobileView ? 'p-0' : 'p-10'}`}>
                            <Text text="Le Ford Nugget 2024 est le compagnon idéal pour vos aventures sur la route.
                                        Ce van aménagé offre un confort inégalé avec ses équipements modernes et son design ergonomique.
                                        Profitez d'un espace de vie optimisé avec une cuisine entièrement équipée, un coin salon convertible en lit confortable, et de nombreux rangements astucieux.
                                        Le Ford Nugget 2024 est également doté de technologies de pointe pour une conduite sécurisée et agréable, incluant des systèmes d'assistance à la conduite et une connectivité avancée.
                                        Que vous partiez pour un week-end ou un long voyage, le Ford Nugget 2024 vous garantit une expérience de voyage exceptionnelle, alliant liberté et confort.
                                        Découvrez dès maintenant les avantages et les caractéristiques uniques de ce van aménagé et préparez-vous à vivre des moments inoubliables sur la route" />
                        </div>
                        <div className={`${isMobileView ? 'py-2' : 'p-10'}`}>
                            <PictoBar pictos={[
                                { icon: faCoins, text: '119€/jour' },
                                { icon: faBed, text: 'Lits confortable 4 places' },
                                { icon: faCar, text: '5 places securisées' },
                                { icon: faGears, text: 'Boite automatique' },
                                { icon: faGasPump, text: 'Diesel' },
                                { icon: faUtensils, text: 'Cuisine équipée' },
                                { icon: faSuitcase, text: 'Nombreux rangements' },
                                { icon: faRoad, text: 'Voyage à l\'étranger autorisé' },
                                { icon: faMapMarkedAlt, text: 'Système de navigation GPS' },
                                { icon: faIdCard, text: 'Permis B' },
                                { icon: faScrewdriverWrench, text: 'Très nombreux équipements' },
                                { icon: faShower, text: 'Douche chaude' },
                                { icon: faToilet, text: 'Toilette' },
                                { icon: faWater, text: 'Eau potable' },
                                { icon: faSmog, text: 'Gaz' },
                                { icon: faWind, text: 'Ventilation continue' },
                            ]} />
                        </div>
                        <div className={`${isMobileView ? 'py-4' : 'p-10'}`}>
                            <Calendar onDateChange={handleDateChange} onConfirm={handleConfirm} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FordNugget;

function getCookie(name) {
    let cookieValue = null;

    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();

            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}