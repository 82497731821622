import * as React from 'react';
import NavBar from './components/Navbar';
import Footer from './components/Footer.js';

import Home from './pages/home/home.js';
import Rent from './pages/rent/rent.js';
import About from './pages/about/about.js';
import FordNugget from './pages/cars/FordNugget.js';
import NotFound from './pages/error/404.js';

import { Routes, Route } from 'react-router-dom';

export default function App() {
	React.useEffect(() => {
		document.documentElement.style.scrollBehavior = 'smooth';
	}, []);

	return (
		<div>
			<div className="my-10">
				<NavBar />
			</div>

			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/rent" element={<Rent />} />
				<Route path="/about" element={<About />} />
				<Route path="/rent/ford_nugget" element={<FordNugget />} />
				<Route path="*" element={<NotFound />} />
			</Routes>

			<div className="my-10">
				<Footer />
			</div>
		</div>
	);
}