import React from 'react';
import DatePicker from 'react-datepicker';
import { useIsMobileView } from '../hooks/useIsMobileView';
import "react-datepicker/dist/react-datepicker.css";
import './css/Calendar.css';

const CustomCalendar = ({
    startDate,
    endDate,
    onChange,
    excludeDates,
    minDate,
    maxDate,
    onMonthChange,
    renderDayContents,
    dailyPrices
}) => {
    const isMobileView = useIsMobileView();

    const renderDay = (day, date) => {
        const dateString = date.toISOString().split('T')[0];
        const priceData = dailyPrices[dateString];

        return (
            <div className="day-content">
                <div className="day-number">{day}</div>
                <div className="day-price">
                    {priceData ? `${priceData.price}€` : '119€'}
                </div>
            </div>
        );
    };

    return (
        <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            excludeDates={excludeDates}
            selectsRange
            inline
            minDate={minDate}
            maxDate={maxDate}
            onMonthChange={onMonthChange}
            renderDayContents={renderDay}
            monthsShown={1}
            showDisabledMonthNavigation
        />
    );
};

export default CustomCalendar;
