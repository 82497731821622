import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIsMobileView } from '../hooks/useIsMobileView';

function Navbar() {
	const location = useLocation();
	const isMobileView = useIsMobileView();
	const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

	const path = location.pathname.split('/')[1];

	const renderMenuItems = () => (
		<>
			<li className={isMobileView ? "mt-2" : ""}>
				<Link to="/" className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${path === '' ? 'text-white bg-blue-700 md:text-blue-700 md:text-blue-500' : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700'}`} aria-current="page" style={{ fontSize: '1rem' }}>Accueil</Link>
			</li>
			<li className={isMobileView ? "mt-2" : ""}>
				<Link to="/rent" className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${path === 'rent' ? 'text-white bg-blue-700 md:text-blue-700 md:text-blue-500' : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700'}`} style={{ fontSize: '1rem' }}>Louer</Link>
			</li>
			<li className={isMobileView ? "mt-2" : ""}>
				<Link to="/about" className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${path === 'about' ? 'text-white bg-blue-700 md:text-blue-700 md:text-blue-500' : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700'}`} style={{ fontSize: '1rem' }}>À propos</Link>
			</li>
		</>
	);

	return (
		<div className="max-w-[1920px] mx-auto">
			<nav className={`relative bg-gray-900 transition-transform duration-300 p-2 ${isMobileView ? 'm-3' : 'mx-10'} bg-opacity-50 backdrop-blur-lg max-w-[1920px]`} style={{ borderRadius: '10px' }}>
				<div className="w-full h-50 flex flex-wrap items-center justify-between p-1 bg-opacity-50 backdrop-blur-lg">
					<Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse pl-3 md:w-1/4">
						<span className="self-center text-2xl font-semibold whitespace-nowrap text-white" style={{ fontSize: '2rem' }}>C' Location</span>
					</Link>
					<div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse md:w-1/4 justify-end">
						<button onClick={toggleMobileMenu} data-collapse-toggle="navbar-user" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600" aria-controls="navbar-user" aria-expanded={isMobileMenuOpen}>
							<span className="sr-only">Open main menu</span>
							<svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
							</svg>
						</button>
					</div>
					{!isMobileView && (
						<div className={`items-center justify-center w-full md:flex md:w-2/4 md:order-1`} id="navbar-user">
							<ul className="flex flex-col font-medium p-6 md:p-0 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 border-gray-700 items-center justify-center w-full">
								{renderMenuItems()}
							</ul>
						</div>
					)}
				</div>
				{isMobileView && (
					<div className={`items-center justify-between ${isMobileMenuOpen ? 'block' : 'hidden'} w-full md:flex md:w-auto md:order-1`} id="navbar-user" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: '10px' }}>
						<ul className="flex flex-col font-medium p-6 md:p-0 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 border-gray-700">
							{renderMenuItems()}
						</ul>
					</div>
				)}
			</nav>
		</div>
	);
}

export default Navbar;
